<template>
  <v-container
    style="padding: 0; height: 100%; background-color: #f7f7f7"
    fluid
  >
    <v-row>
      <v-col cols="12" style="padding: 0">
        <AppHeaderUser v-if="user" />
        <AppHeader v-else />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="auto"
        style="padding-bottom: 0; padding-top: 110px; padding-left: 31px"
      >
        <Images name="404.svg" />
      </v-col>
      <div style="width: 100%"></div>
      <v-col cols="auto" style="padding-bottom: 2px">
        <Images name="404_bottom.svg" />
      </v-col>
      <div style="width: 100%"></div>
      <v-col cols="auto">
        <Texting type="p3 Fivethly">Page Not Found</Texting>
      </v-col>
      <div style="width: 100%"></div>
      <v-col cols="auto" style="text-align: center; padding-bottom: 0">
        <Texting type="p2 grey6"
          >It looks like you may have taken a wrong turn. Don't worry... it
          happens to the</Texting
        >
      </v-col>
      <div style="width: 100%"></div>
      <v-col
        cols="auto"
        style="text-align: center; padding-bottom: 28px; padding-top: 0"
      >
        <Texting type="p2 grey6"
          >best of us. Here's a little tip that might help you get back on
          track.</Texting
        >
      </v-col>
      <div style="width: 100%"></div>
      <router-link to="/">
        <v-col cols="auto" style="padding-bottom: 110px">
          <Button
            id="not-found-back-button-290e8446"
            :handleClick="function () {}"
            type="activate"
            size="large"
            >back home</Button
          >
        </v-col>
      </router-link>
    </v-row>
    <div style="padding-bottom: 110px" />
    <AppFooter />
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Texting from "../components/Texting";
import Button from "../components/Button.vue";
import Images from "../components/MyImages";
import AppHeaderUser from "../components/AppHeaderUser";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
export default {
  components: {
    Images,
    Button,
    Texting,
    AppHeaderUser,
    AppHeader,
    AppFooter,
  },
  computed: {
    ...mapState({
      user: "user",
      isAuthResolved: "isAuthResolved",
    }),
  },
};
</script>
